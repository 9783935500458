<template>
  <div>
    <b-dropdown toggle-class="language w-100" menu-class="drop-menu">
        <template v-slot:button-content>
            {{ $t('common.show') }} {{ perPage }} {{ $t('common.entries') }}
            <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
        </template>

        <b-dropdown-item
            class="language-menu-item"
            v-for="(page, i) in pageOptions"
            :key="`page${i}`"
            @click="handleInput(page)"
            :link-class="{'active': page === perPage, 'language-menu-item d-flex': true}"
        >
            <div class="my-auto">
                {{ $t('common.show') }} {{ page }} {{ $t('common.entries') }}
            </div>
        </b-dropdown-item>
    </b-dropdown>
    <div class="showing-1-to">Showing {{ ((currentPage - 1) * perPage) + 1 }} to {{ ((currentPage - 1) * perPage) + tableRowsCount }} rows of {{ totalRows }} results</div>
  </div>
</template>

<script>
export default {
    prop: ['value'],
    props: {
        currentPage: Number,
        totalRows: Number,
        tableRowsCount: Number
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
        }
    },
    methods: {
        handleInput (value) {
            this.perPage = value
            this.$emit('input', this.perPage)
        }
    }
}
</script>

<style scoped>
.showing-1-to {
    font-size: 14px;
    font-weight: 350;
    color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    padding-left: 15px;
}

::v-deep .btn-secondary {
    padding: 21px 25px;
    color: #000 !important;
    border-radius: 15px;
    border: 1px solid #DCDAD5;
    background-color: rgba(220, 218, 213, 0.30) !important;
}
</style>