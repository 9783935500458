<template>
    <div class="menu-content">
        <div class="d-flex" style="gap: 10px;">
            <b-dropdown variant="white" toggle-class="language" menu-class="drop-menu" style="width: 136px;">
                <template v-slot:button-content>
                    {{ day }}
                    <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                </template>

                <b-dropdown-item
                    class="time-menu-item"
                    v-for="(option, i) in dayOptions"
                    :key="`day${i}`"
                    @click="dayChanged(option)"
                    :link-class="{'active': option === day, 'time-menu-item d-flex': true}"
                >
                    <div class="my-auto">
                        {{ option }}
                    </div>
                </b-dropdown-item>
            </b-dropdown>
            <date-picker
                :type="dateType"
                :format="dateFormat"
                v-model="selectedDates"
                range
                :clearable="false"
                :open.sync="openDatePicker"
                name="startdate"
                prefix-class="xmx"
                @change="day = 'Custom'"
            >
            <i slot="icon-calendar">
                <img src="@/assets/images/calendar.png" alt="Filter">
            </i>
            </date-picker>
        </div>
        <div class="d-flex" style="gap: 10px;">
            <b-btn v-if="!hideFilter" variant="white" @click="toggleFilter">
                <img style="padding-right: 10px;" src="@/assets/images/filter.svg" alt="Filter">{{ $t('common.filters') }}
            </b-btn>
            <b-btn v-if="showEditColumn" @click="toggleEditColumn" variant="white">
                <img style="padding-right: 10px;" src="@/assets/images/edit_columns.svg" alt="Edit Columns">Edit Columns
            </b-btn>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import * as moment from "moment";
export default {
    props: {
        showEditColumn: Boolean,
        selectedDay: {
            default: 'All time',
            type: String
        },
        dateFormat: {
            default: 'DD/MM/YYYY HH:mm',
            type: String
        },
        dateType: {
            default: 'datetime',
            type: String
        },
        hideFilter: {
            default: false,
            type: Boolean
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            openDatePicker: false,
            day: "",
            dayOptions: ['Today', 'Yesterday', 'Last week', 'Last month', 'This year', 'All time', 'Custom'],
            selectedDates: [],
        }
    },
    watch: {
        selectedDates(value) {
            this.$emit('date-change', value);
        }
    },
    created() {
      this.dayChanged(this.selectedDay);

    },
    methods: {
        dayChanged(option) {
            this.day = option;
            let startDate, endDate;
            switch (this.day) {
                case 'Today':
                    startDate = moment();
                    endDate = moment();
                    break;
                case 'Yesterday':
                    startDate = moment().subtract(1, 'days');
                    endDate = moment().subtract(1, 'days');
                    break;
                case 'Last week':
                    startDate = moment().subtract(1, 'weeks').startOf('week');
                    endDate = moment().subtract(1, 'weeks').endOf('week');
                    break;
                case 'Last month':
                    startDate = moment().subtract(1, 'months').startOf('month');
                    endDate = moment().subtract(1, 'months').endOf('month');
                    break;
                case 'This year':
                    startDate = moment().startOf('year');
                    endDate = moment();
                    break;
                case 'All time':
                    startDate = moment('2018-01-01T00:00:00.000');
                    endDate = moment();
                    break;
                case 'Custom':
                    this.openDatePicker = true;
                    break;
                default:
                    break;
            }
            if(startDate && endDate) {
                startDate.set({ 'hour': 0, 'minute': 0, 'second': 0 });
                endDate.set({ 'hour': 23, 'minute': 59, 'second': 59 });
                this.selectedDates = [
                    new Date(startDate),
                    new Date(endDate)
                ];
                console.log(this.selectedDates);
            }
        },
        toggleFilter() {
            this.$emit('toggle-filter');
        },
        toggleEditColumn() {
            this.$emit('toggle-edit-column');
        }
    }
}
</script>

<style scoped>
::v-deep .xmx-input {
    /* padding-left: 30px; */
    padding: 13px;
    padding-left: 35px;
    height: 44px;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    background: transparent;
    font-size: 14px;
    font-weight: 350;
    color: #000;
}
::v-deep .xmx-datepicker {
    width: 330px;
}

.xmx-icon-calendar img{
    width: 17px;
    height: 17px;
}

::v-deep .xmx-icon-calendar {
    left: 10px;
}

.custom-dropdown-select {
    width: 222px;
}

button {
    border-radius: 15px;
    padding: 13px 16px;
    height: 44px;
    border: 1px solid #DCDAD5;
    font-size: 14px;
}
::v-deep .dropdown-menu{
    min-width: 136px;
}
::v-deep .dropdown-item{
    padding: 13px 16px;
}

</style>